import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import classes from "./ForgetPasswordPage.module.css";
import InputClasses from "../../Components/Input/input.module.css";
import { apiHeader, BaseURL, validateEmail } from "../../config/apiUrl";
import { Post } from "../../Axios/AxiosFunctions";
import { Logo } from "../../constant/imagePath";
import Button from "../../Components/Button";
import Input from "../../Components/Input";

const ForgetPasswordPage = () => {
  // variables and states
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const url = BaseURL("auth/forgotPassword");
  const header = apiHeader();

  // functions Handle Reset Password Email
  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address");
    }

    setLoading(true);
    const response = await Post(url, { email }, header);
    setLoading(false);
    if (response !== undefined) {
      navigate("/verify-otp", {
        state: {
          email: email,
        },
      });
    }
  };

  return (
    <Container fluid className={classes.forgotContainer}>
      <style jsx>{`
        .${InputClasses.Container} {
          width: 100%;
        }
        .${InputClasses.inputPassContainer} {
          background: white;
        }
        ${error &&
        `.${InputClasses.inputPassContainer} {
          border-color:#ff2c2c;
        }`}

        .${InputClasses.leftIconBox} > svg {
          width: 25px;
          height: 25px;
        }
        .${InputClasses.inputBox} {
          padding-left: 55px !important;
        }
      `}</style>

      <Row className={`g-0 ${classes.rowConatiner}`}>
        <Col md={6} className={classes.leftContainer}></Col>

        <Col md={6} className={classes.rightContainer}>
          <div className={classes.imageContainer}>
            <img src={Logo} alt="logo" />
          </div>

          <h3 className={classes.heading}>Forgot Your Password?</h3>
          <span className={classes.description}>
            No worries! Enter your email and we will send you a reset code.
          </span>

          <Input
            state={email}
            setState={setEmail}
            placeholder="Email"
            type="email"
            customStyle={{
              borderRadius: "10px",
            }}
            inputContainerClass={classes.input}
            onBlur={() => {
              if (!validateEmail(email)) {
                setError(true);
              }
            }}
            onFocus={() => {
              setError(false);
            }}
          />

          <span className={classes.errorMessage}>
            {error && "Enter a valid email address"}
          </span>

          <div className={classes.buttonContainer}>
            <Button
              label={"Reset"}
              className={[classes.generalButton, classes.resetButton].join(" ")}
              disabled={loading}
              onClick={() => {
                setEmail("");
              }}
            />
            <Button
              label={loading ? "Please wait" : "Submit"}
              className={[classes.generalButton, classes.submitButton].join(
                " "
              )}
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetPasswordPage;
