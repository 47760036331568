import React from "react";
import { useSelector } from "react-redux";
import { Route, Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ element: Element }) {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);

  return <>{isAuthenticated ? Element : <Navigate replace to="/login" />}</>;
}
export default ProtectedRoute;

// import React from "react";
// import { useSelector } from "react-redux";
// import { Route, Navigate } from "react-router-dom";

// function ProtectedRoute({ element: Element, ...restOfProps }) {
//     const isAuthenticated = useSelector((state) => state.authReducer.isLogin);

//     return (
//         <Route
//             {...restOfProps}
//             element={isAuthenticated ? Element : <Navigate replace to="/" />}
//         />
//     );
// }
// export default ProtectedRoute;
