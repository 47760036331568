import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyATjcnvPsR5xJZfn4NkN-bFTyXfjtgSs90",
  authDomain: "mobile-mechaic.firebaseapp.com",
  projectId: "mobile-mechaic",
  storageBucket: "mobile-mechaic.appspot.com",
  messagingSenderId: "798590717533",
  appId: "1:798590717533:web:32fe3cdbec9f88620347b4",
  measurementId: "G-BGJ4SQRVH5",
};
let messaging;
if (firebase.messaging.isSupported()) {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
} else {
  console.log("no-support :(");
}

export { messaging };
export default firebase;
