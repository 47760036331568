import React, { useEffect, useState } from "react";
import classes from "./PortfoliosPage.module.css";
import Button from "../../Components/Button";
import NoData from "../../Components/NoData/NoData";
import PaginationComponent from "../../Components/PaginationComponent";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import {
  BaseURL,
  recordsLimit,
  apiHeader,
  imageUrl,
} from "../../config/apiUrl";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Get, Delete, Post, Patch } from "../../Axios/AxiosFunctions";
import AreYouSureModal from "../../Modals/AreYouSureModal";
import UploadImageBox from "../../../src/Components/UploadImageBox/index";
import { Col, Row } from "react-bootstrap";
import AddEditPortfolioModal from "../../Modals/AddEditPortfolioModal";
import TableSkeleton from "../../Components/TableSkeleton";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";






const PortfoliosPage = () => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(recordsLimit);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100);
  const [heading, setHeading] = useState('');


  const [isApiCall, setIsApiCall] = useState('')
  const [isModalOpen, setIsModalOpen] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)


  useEffect(() => {
    getAllData(page);
  }, [page]);

  // getAllData
  const getAllData = async (pageNo = page) => {

    const url = BaseURL(
      `portfolio/admin/all?page=${pageNo}&limit=${recordsLimit}`
    );
    setLoading(true);
    const apiResponse = await Get(url, accessToken);
    if (apiResponse !== undefined) {

      setResponseData(apiResponse?.data?.data?.images);
      setHeading(apiResponse?.data?.data?.heading);
      setTotalPages(apiResponse?.data?.totalCount);
    }
    setLoading(false);
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const formData = new FormData();
    formData.append("image", params?.image);
    formData.append("width", params?.width);
    formData.append("height", params?.height);

    const apiUrl =
      isModalOpen == "add"
        ? BaseURL(`portfolio`)
        : BaseURL(`portfolio/${selectedItem?._id}`);
    setIsApiCall(isModalOpen == 'add' ? 'add' : 'edit');
    const apiFunc = isModalOpen == 'add' ? Post : Patch
    const response = await apiFunc(apiUrl, formData, apiHeader(accessToken))
    if (response !== undefined) {
      const newData = [...responseData]
      const index = newData?.findIndex(item => item?._id == response?.data?.data?._id)

      if (isModalOpen == 'add') {
        newData.unshift(response?.data?.data)
      }
      else {
        newData.splice(index, 1, response?.data?.data)
      }
      setResponseData(newData)
      toast.success(
        `Portfolio ${isModalOpen == "add" ? "added" : "edited"} successfully`
      );
      setIsModalOpen('');
    }
    setIsApiCall('')
  };

  // // deleteImageHandler

  const handleDelete = async () => {
    const url = BaseURL(`portfolio/${selectedItem?._id}`);
    setIsApiCall('delete');
    const response = await Delete(url, null, apiHeader(accessToken));

    if (response !== undefined) {
      const dataCopy = [...responseData];
      const index = dataCopy?.findIndex(
        (item) => item._id == selectedItem?._id
      );
      dataCopy?.splice(index, 1);
      setResponseData(dataCopy);
      toast.success(`Portfolio deleted successfully!`);
      setIsModalOpen('')
    }
    setIsApiCall('');
  };
  // Update Heading
  const updateHeading = async () => {
    setIsApiCall('heading')
    const formData = new FormData();
    formData.append('heading', heading?.heading);
    const response = await Patch(BaseURL(`portfolio/${heading?._id}`), formData, apiHeader(accessToken))
    if (response !== undefined) {
      toast.success(
        `Portfolio heading updated successfully`
      );
      setHeading(response?.data?.data)
    }
    setIsApiCall('')
  };

  return (
    <SideBarSkeleton heading={"Portfolios"}>
      <div className={classes?.mainContainer}>
        <style>{`
        .table100-body{
          height:calc(100vh - 360px);
        }
       
        `}</style>

        <div className={classes.mainHeader}>
          <div className={classes.inputWithBtn}>
            <Input placeholder={'Heading'} value={heading?.heading} setter={(e) => setHeading({ ...heading, heading: e })} />
            <Button label={isApiCall == 'heading' ? 'Wait' : 'Update'} onClick={updateHeading} />
          </div>

          <Button
            onClick={() => {
              setSelectedItem(null)
              setIsModalOpen('add')
            }}
            label={"Add Portfolio"}
          />

        </div>

        <div class="table100 ver1 m-b-110">
          <div class="table100-head">
            <table>
              <thead>
                <tr class="row100 head">
                  <th
                    class="cell100 column1"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    S.No
                  </th>
                  <th
                    class="cell100 column2"
                    style={{ width: "40%", textAlign: "left" }}
                  >
                    Image
                  </th>
                  {/* <th
                    class="cell100 column2"
                    style={{ width: "25%", textAlign: "left" }}
                  >
                    Status
                  </th> */}

                  <th
                    class="cell100 column4"
                    style={{ width: "40%", textAlign: "left" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          {loading ? (
            <TableSkeleton rowsCount={16} colsCount={3} tableBodyClass={classes.tableBodyHeight} />
          ) : (
            <div class="table100-body js-pscroll ps ps--active-y">
              <table>
                <tbody>
                  {responseData?.length > 0 ?
                    responseData?.map((item, index) => (
                      <tr class="row100 body" key={item?._id}>
                        <td
                          class="cell100 column1"
                          style={{ width: "20%", textAlign: "left" }}
                        >
                          {++index}
                        </td>
                        <td
                          class="cell100 column2"
                          style={{
                            width: "40%",
                            textAlign: "left",
                          }}
                        >
                          <div className={classes.imgDiv}>
                            <img src={imageUrl(item?.imageKey)} />
                          </div>
                        </td>
                        {/* <td
                          class="cell100 column2"
                          style={{
                            width: "25%",
                            textAlign: "left",
                          }}
                        >
                          {item?.isActive ? <span className={'status-active'}>Activated</span> : <span className={'status-deactivate'}>Deactivated</span>}
                        </td> */}

                        <td
                          class="cell100 column2"
                          style={{
                            width: "40%",
                            // textAlign: "center",
                          }}
                        >
                          <div className={classes.btnMain}>
                            <Button
                              onClick={() => {
                                setSelectedItem(item)
                                setIsModalOpen('edit')
                                // setDetailData(item);
                              }}
                              label={"Edit"}
                            />
                            <Button
                              onClick={() => {
                                setSelectedItem(item)
                                setIsModalOpen('delete')
                              }}
                              label={"Delete"}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                    ) : (
                      <NoData text={"No Portfolios Found"} />
                    )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {responseData?.length > 0 && (
          <div className={[classes.paginationDiv]}>
            <PaginationComponent
              totalPages={Math.ceil(totalPages / limit)}
              currentPage={page}
              setCurrentPage={setPage}
            />
          </div>
        )}
      </div>
      {isModalOpen == 'delete' && (
        <AreYouSureModal
          show={isModalOpen == 'delete'}
          setShow={() => setIsModalOpen('')}
          subTitle={`Do you really want to delete this portfolio`}
          onClick={handleDelete}
          isApiCall={isApiCall == 'delete'}
        />
      )}

      {(isModalOpen == 'edit' || isModalOpen == 'add') && <AddEditPortfolioModal
        show={isModalOpen == 'edit' || isModalOpen == 'add'}
        setShow={() => setIsModalOpen('')}
        data={selectedItem}
        onClick={addAndupdateItemHandler}
        isLoading={isApiCall == 'edit' || isApiCall == 'add'}
      />}
    </SideBarSkeleton>
  );
};

export default PortfoliosPage;
