import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";

import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import ProtectedRoute from "./Helper/ProtectedRoute";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";

import Login from "./Pages/Login";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
import VerifyCodePage from "./Pages/VerifyCodePage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";

import Dashboard from "./Pages/Dashboard";
import LeadsPage from "./Pages/LeadsPage/LeadsPage";
import HomePageCms from "./Pages/HomePageCms/index";
import Portfolio from "./Pages/PortfoliosPage/PortfoliosPage";
import DailyWorks from "./Pages/DailyWorksPage/DailyWorksPage";
import Wallet from "./Pages/Wallet";
import Packages from "./Pages/Packages";
import ProfileSetting from "./Pages/ProfileSetting";
import { SaveFcmToken } from "./store/auth/authSlice";
import firebase, { messaging } from "./firebase";
import { firebaseVapidObject } from "./config/apiUrl";
import ContactUs from "./Pages/ContactUs";
import FooterPageCms from "./Pages/FooterPageCms";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authReducer?.user);

  useEffect(() => {
    // const getToken = async () => {
    //   let token = "";
    //   if (
    //     Notification.permission == "granted" &&
    //     firebase.messaging.isSupported()
    //   ) {
    //     token = await messaging.getToken(firebaseVapidObject);
    //     dispatch(SaveFcmToken(token));
    //   }
    // };

    // getToken();
  }, []);

  return (
    <>
      <ToastContainer />

      <BrowserRouter>
        <Routes>
          {/* Common Routes */}
          <Route
            path="/login"
            exact
            element={<BeforeLoginRoute element={<Login />} />}
          />
          <Route
            path="/forget-password"
            exact
            element={<BeforeLoginRoute element={<ForgetPasswordPage />} />}
          />
          <Route
            path="/verify-otp"
            exact
            element={<BeforeLoginRoute element={<VerifyCodePage />} />}
          />
          <Route
            path="/reset-password"
            exact
            element={<BeforeLoginRoute element={<ResetPasswordPage />} />}
          />

          {/* <Route
            path="/"
            exact
            element={<ProtectedRoute element={<Dashboard />} />}
          /> */}
          <Route
            path="/leads"
            exact
            element={<ProtectedRoute element={<LeadsPage />} />}
          />
          <Route
            path="/home-cms"
            exact
            element={<ProtectedRoute element={<HomePageCms />} />}
          />
          <Route
            path="/"
            exact
            element={<ProtectedRoute element={<Portfolio />} />}
          />
          <Route
            path="/daily-works"
            exact
            element={<ProtectedRoute element={<DailyWorks />} />}
          />
          <Route
            path="/contact-us"
            exact
            element={<ProtectedRoute element={<ContactUs />} />}
          />
          <Route
            path="/footer-cms"
            exact
            element={<ProtectedRoute element={<FooterPageCms />} />}
          />
          {/* <Route
            path="/setting"
            exact
            element={<ProtectedRoute element={<ProfileSetting />} />}
          /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
