import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import classes from "./ResetPasswordPage.module.css";
import { Logo } from "../../constant/imagePath";
import InputClasses from "../../Components/Input/input.module.css";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import Input from "../../Components/Input";
import Button from "../../Components/Button";

const ResetPasswordPage = () => {
  // variables and states
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const header = apiHeader();

  const url = BaseURL(`auth/confirm-password`);

  // functions Handle Change Password
  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      return toast.warning("Password should match with confirm password!");
    }

    // if (
    //   !validator.isStrongPassword(password, [
    //     {
    //       minLength: 8,
    //       minLowercase: 1,
    //       minUppercase: 1,
    //       minSymbols: 1,
    //       minNumbers: 1,
    //     },
    //   ])
    // ) {
    //   return toast.error(
    //     "Password must contain at least 8 Characters, One Uppercase, Lowercase, Special Symbols, Numbers"
    //   );
    // }

    setLoading(true);
    const response = await Patch(
      url,
      {
        email,
        password,
        passwordConfirm: confirmPassword,
      },
      header
    );
    setLoading(false);
    if (response !== undefined) {
      toast.success("Password changed successfully!");
      navigate({
        pathname: "/",
      });
    }
  };

  return (
    <Container fluid className={classes.forgotContainer}>
      <style jsx>{`
        .${InputClasses.Container} {
          width: 100%;
        }
        .${InputClasses.inputPassContainer} {
          background: white;
        }

        .${InputClasses.leftIconBox} > svg {
          width: 25px;
          height: 25px;
        }
        .${InputClasses.inputBox} {
          padding-left: 55px !important;
        }
      `}</style>

      <Row className={`g-0 ${classes.rowConatiner}`}>
        <Col md={6} className={classes.leftContainer}></Col>

        <Col md={6} className={classes.rightContainer}>
          <div className={classes.imageContainer}>
            <img src={Logo} alt="logo" />
          </div>

          <h3 className={classes.heading}>Create New Password!</h3>
          <span className={classes.description}>
            To keep your valuable information in workzone safe, we require that
            you use a strong password.
          </span>

          <Input
            placeholder="Enter Password..."
            type="password"
            state={password}
            setState={setPassword}
            customStyle={{
              borderRadius: "10px",
              marginBottom: "20px",
            }}
            inputContainerClass={[classes.input, classes.mb20].join(" ")}
          />

          <Input
            placeholder="Enter Confirm Password..."
            type="password"
            state={confirmPassword}
            setState={setConfirmPassword}
            customStyle={{
              borderRadius: "10px",
              marginBottom: "20px",
            }}
            inputContainerClass={classes.input}
          />

          <div className={classes.buttonContainer}>
            <Button
              label={"Reset"}
              className={[classes.generalButton, classes.resetButton].join(" ")}
              disabled={loading}
              onClick={() => {
                setPassword("");
                setConfirmPassword("");
              }}
            />
            <Button
              label={loading ? "Please wait" : "Change Password"}
              className={[classes.generalButton, classes.submitButton].join(
                " "
              )}
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordPage;
