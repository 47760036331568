import React, { useState } from "react";
import Style from "./AfterLoginHeader.module.css";
import { Container, OverlayTrigger } from "react-bootstrap";
import { MdOutlineNotifications } from "react-icons/md";
import HeaderNotification from "../HeaderNotification/HeaderNotification";
import { imageUrl } from "../../config/apiUrl";
import { ProfileImg } from "../../constant/imagePath";
import { useNavigate } from "react-router-dom";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";

const SettingsOverlay = () => {
  const navigate = useNavigate();

  return (
    <div className={[Style.SettingsPopup]}>
      <div
        className={[Style.editprofileDiv]}
        onClick={() => navigate("/change-password")}
      >
        <BsFillShieldLockFill size={20} className={[Style.editProfileIcons]} />{" "}
        <span>Change Password</span>
      </div>
      <div
        className={[Style.editprofileDiv]}
        onClick={() => navigate("/setting")}
      >
        <AiFillSetting size={20} className={[Style.editProfileIcons]} />{" "}
        <span>Settings</span>
      </div>
    </div>
  );
};

const AfterLoginHeader = ({ className, heading = "" }) => {
  const [showNotificationOverlay, setShowNotificationOverlay] = useState(false);
  const [showSettingsOverlay, setShowSettingsOverlay] = useState(false);

  return (
    <Container className={[Style.navbarContainer, className].join(" ")}>
      <div className={Style?.headingContainer}>
        <span>{heading}</span>
      </div>
      <div className={[Style.rightContainer].join(" ")}>
        {/* <OverlayTrigger
          placement={"bottom"}
          show={showNotificationOverlay}
          trigger={["click"]}
          overlay={
            <div className={[Style.notifyoverlayDiv]}>
              <HeaderNotification />
            </div>
          }
          onToggle={() => setShowNotificationOverlay(!showNotificationOverlay)}
        >
          <div className={[Style.notificationIconContainer]}>
            <MdOutlineNotifications
              color={"var(--dashboard-main-color)"}
              size="34px"
              className={Style.notifyIcon}
            />
          </div>
        </OverlayTrigger> */}

        {/* <OverlayTrigger
          placement={"bottom"}
          show={showSettingsOverlay}
          trigger={["click"]}
          overlay={
            <div className={[Style.SettingsoverlayDiv]}>
              <SettingsOverlay />
            </div>
          }
          onToggle={() => setShowSettingsOverlay(!showSettingsOverlay)}
        >
          <div className={[Style.profileImg]}>
            <img src={ProfileImg} alt="..." />
          </div>
        </OverlayTrigger> */}

      </div>
    </Container>
  );
};

export default AfterLoginHeader;
