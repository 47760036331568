import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./ViewContactDetail.module.css";
const ViewContactDetail = ({ show, setShow, detailData }) => {
  return (
    <>
      <ModalSkeleton
        setShow={setShow}
        show={show}
        width={"630px"}
        borderRadius={"10px"}
      >
        <div className={classes.content}>
          <h3>Contact Detail</h3>
          <h6>Name</h6>
          <span>
            <p>{detailData?.name}</p>
          </span>
          <h6>Email</h6>
          <span>
            <p>{detailData?.email}</p>
          </span>
          <h6>Message</h6>
          <span>
            <p>{detailData?.message}</p>
          </span>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default ViewContactDetail;
