import React, { useEffect, useState } from "react";
import classes from "./LeadsPage.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import NoData from "../../Components/NoData/NoData";
import TableSkeleton from "../../Components/TableSkeleton";
import PaginationComponent from "../../Components/PaginationComponent";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import { BaseURL, imageUrl, recordsLimit } from "../../config/apiUrl";
import DropDown from "../../Components/DropDown";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import moment from "moment";
import AreYouSureModal from "../../Modals/AreYouSureModal";
import LeadDetailModal from "../../Modals/LeadDetailModal/LeadDetailModal";

const LeadsPage = () => {
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(recordsLimit);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100);

  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [deletingItem, setDeletingItem] = useState(null);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);

  // Detail modal states
  const [isLeadDetailModalOpen, setIsLeadDetailModalOpen] = useState(false);

  useEffect(() => {
    getAllData(page);
  }, [page]);

  // getAllData
  const getAllData = async (pageNo) => {
    const url = BaseURL(`contact-us?page=${pageNo}&limit=${recordsLimit}`);
    setLoading(true);
    const apiResponse = await Get(url, accessToken);
    if (apiResponse !== undefined) {
      setResponseData(apiResponse?.data?.data);
      setTotalPages(apiResponse?.data?.totalCount);
    }
    setLoading(false);
  };

  // addAndupdateItemHandler
  // const addAndupdateItemHandler = async (params) => {
  //   const url = BaseURL("newsLetter/reply");

  //   setIsAddEditApiCall(true);
  //   const response = await Post(url, params, apiHeader(accessToken));
  //   setIsAddEditApiCall(false);

  //   if (response !== undefined) {
  //     toast.success("Email Broadcasted successfully.");
  //   }
  // };

  // // deleteItemHandler
  // const deleteItemHandler = async () => {
  //   const url = BaseURL(`newsLetter/${deletingItem}`);

  //   setIsDeleteApiCall(true);
  //   const response = await Delete(url, null, apiHeader(accessToken));
  //   setIsDeleteApiCall(false);

  //   if (response !== undefined) {
  //     const dataCopy = [...apiData];
  //     const index = dataCopy.findIndex((item) => item._id === deletingItem);
  //     dataCopy.splice(index, 1);
  //     setApiData(dataCopy);
  //     setAreYouSureModalOpen(false);
  //     toast.success(`Item deleted succeccfully!`);
  //   }
  // };

  return (
    <SideBarSkeleton heading={"Leads"}>
      <div className={classes?.mainContainer}>
        <style>{`
        .table100-body{
          height:calc(100vh - 360px);
        }
        `}</style>
        {/* <div className={classes?.headingContainer}>
          <div></div>
          <DropDown
            setter={(e) => {
              setLimit(e?.label);
              setPage(1);
            }}
            value={{ label: limit }}
            placeholder={"select Limit"}
            customStyle={{
              borderRadius: "10px",
              border: "1px solid var(--input-border-color)",
              padding: "2px 15px",
            }}
            options={[{ label: 10 }, { label: 25 }, { label: 50 }]}
            optionLabel={"label"}
            optionValue={"label"}
          />
        </div> */}
        <div class="table100 ver1 m-b-110">
          <div class="table100-head">
            <table>
              <thead>
                <tr class="row100 head">
                  <th
                    class="cell100 column1"
                    style={{ width: "10%", textAlign: "left" }}
                  >
                    S.No
                  </th>
                  <th
                    class="cell100 column2"
                    style={{ width: "50%", textAlign: "left" }}
                  >
                    Name
                  </th>
                  <th
                    class="cell100 column2"
                    style={{ width: "50%", textAlign: "left" }}
                  >
                    Email
                  </th>
                  <th
                    class="cell100 column3"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    Subscribed At
                  </th>
                  <th
                    class="cell100 column3"
                    style={{ width: "20%", textAlign: "left" }}
                  >
                    Message
                  </th>
                  <th
                    class="cell100 column4"
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          {loading ? (
            <TableSkeleton rowsCount={limit} colsCount={7} />
          ) : (
            <div class="table100-body js-pscroll ps ps--active-y">
              <table>
                <tbody>
                  {responseData?.length > 0 ? (
                    responseData?.map((item, index) => (
                      <tr class="row100 body" key={item?._id}>
                        <td
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "left" }}
                        >
                          {++index}
                        </td>
                        <td
                          class="cell100 column2"
                          style={{
                            width: "50%",
                            textAlign: "left",
                          }}
                        >
                          {item?.name}
                        </td>
                        <td
                          class="cell100 column2"
                          style={{
                            width: "50%",
                            textAlign: "left",
                          }}
                        >
                          {item?.email}
                        </td>
                        <td
                          class="cell100 column3"
                          style={{ width: "20%", textAlign: "left" }}
                        >
                          {moment(item?.createdAt).format(
                            "ddd, DD MMM YYYY hh:mm A"
                          )}
                        </td>
                        <td
                          class="cell100 column3"
                          style={{ width: "20%", textAlign: "left" }}
                        >
                          {item?.message}
                        </td>
                        <td
                          class="cell100 column4"
                          style={{ width: "20%", textAlign: "center" }}
                        >
                          <Button
                            className={classes.detail_btn}
                            label={"Delete"}
                            onClick={() => {
                              setDeletingItem(item);
                              setAreYouSureModalOpen(true);
                            }}
                          />
                          <div className="mt-2" />
                          <Button
                            className={classes.detail_btn}
                            label={"Detail"}
                            onClick={() => {
                              setDeletingItem(item);
                              setIsLeadDetailModalOpen(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData text={"No Listing Found"} />
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className={[classes.paginationDiv]}>
          <PaginationComponent
            totalPages={Math.ceil(totalPages / limit)}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      </div>
      {areYouSureModalOpen && (
        <AreYouSureModal
          show={areYouSureModalOpen}
          setShow={setAreYouSureModalOpen}
          subTitle={`Do you really want to delete ${deletingItem?.email}`}
          // onClick={deleteItemHandler}
          // isApiCall={isDeleteApiCall}
        />
      )}

      {isLeadDetailModalOpen && (
        <LeadDetailModal
          show={isLeadDetailModalOpen}
          setShow={setIsLeadDetailModalOpen}
          data={deletingItem}
        />
      )}
    </SideBarSkeleton>
  );
};

export default LeadsPage;
