import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Post } from "../../Axios/AxiosFunctions";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import { saveLoginUserData } from "../../store/auth/authSlice";
import classes from "./Login.module.css";

const Login = () => {
  const fcmToken = useSelector((state) => state?.authReducer?.fcmToken);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const headers = apiHeader();

  const handleLogin = async () => {
    const params = {
      email,
      password,
      // rememberMe: rememberMe[0] == "REMEMBER ME" ? true : false,
      fcmToken,
      role: "admin",
    };
    for (let key in params) {
      if (params[key] === "") {
        return toast.error("Please fill all the required fields!");
      }
    }
    const url = BaseURL("users/admin-login");
    setLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      await dispatch(saveLoginUserData(response?.data));
      toast.success("Successfully Login");
    }
    setLoading(false);
  };
  return (
    <div className={[classes.mainContainer]}>
      <div className={[classes.innerContainer]}>
        <Row>
          <Col md={12}>
            <div className={classes.loginText}>
              <h3>LOGIN</h3>
            </div>
          </Col>
          <Col md={12}>
            <div className={[classes.inputCol]}>
              <Input
                placeholder="Email"
                type="email"
                value={email}
                setter={setEmail}
                label={"Email"}
              />
            </div>
            <div className={[classes.inputCol]}>
              <Input
                placeholder="Password"
                value={password}
                setter={setPassword}
                type="password"
                label={"Password"}
              />
            </div>
            {/* <div className={[classes.inputCol]}>
                <Checkbox
                  value={rememberMe}
                  setValue={setRememberMe}
                  label={"REMEMBER ME"}
                  labelStyle={{
                    color: "var(--dashboard-main-color)",
                    fontSize: "18px",
                    fontFamily: "inter-bold",
                  }}
                />
              </div> */}
            {/* </form> */}

            <Button
              className={classes.loginBtn}
              label={loading ? "SUBMITTING..." : "LOGIN"}
              onClick={handleLogin}
              disabled={loading}
            />
            {/* <div className={classes.forgetPasswordDiv}>
                <Link
                  className={[classes.forgetPasswordText]}
                  to="/forgot-password"
                >
                  Forgot your password?
                </Link>
              </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
