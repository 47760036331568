import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditDailyWorkModal.module.css";
import UploadImageBox from "../../Components/UploadImageBox/index";
import Button from "../../Components/Button";
import { toast } from "react-toastify";
const AddEditDailyWorkModal = ({
  show,
  setShow,
  onClick,
  isLoading,
  isRequest,
  data
}) => {
  const [image, setImage] = useState({});
  useEffect(()=>{
    setImage(data?.imageKey)
  },[data])

  return (
    <>
      <ModalSkeleton show={show} setShow={setShow} width={"600px"}
        header={`${data ? "Edit" : "Add"} Daily Work`}
      >
        <div className={classes.contentMain}>
          <Row>

            <Col md={12} className={classes.formCol}>
              <UploadImageBox
                state={image}
                setter={setImage}
                // label="home Section1 CoverImage"
                hideDeleteIcon={true}
              //   onDelete={() => deleteImageHandler(img)}
              />
            </Col>
            <Col md={12}>
              <div className={classes.btnMain}>
                <Button
                  onClick={() => {
                    if(image =="" || image ==null){
                      return toast.error('Please select daily work image')
                    }
                    onClick({ image })
                  
                  }}
                  label={
                    isLoading ?
                      "Submitting..."
                      :
                      data ?
                        "Edit Daily Work"
                        :
                        "Add Daily Work"}
                  disabled={isLoading}

                />

              </div>
            </Col>
          </Row>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default AddEditDailyWorkModal;
