import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { io } from "socket.io-client";
import { useLocation, useNavigate } from "react-router-dom";
import { Logo, logoMain } from "../../constant/imagePath";
import { FaCertificate, FaClipboardList, FaUsers, FaTasks } from "react-icons/fa";
import { VscRepo } from "react-icons/vsc";
import { RiArrowDownSFill, RiArrowUpSFill, RiListCheck2 } from "react-icons/ri";
// import { GrProjects } from "react-icons/gr";
import { AiFillContainer, AiFillDashboard } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { MdEmail, MdHomeRepairService } from "react-icons/md";
import { ImTicket } from "react-icons/im";
import { signOutRequest } from "../../store/auth/authSlice";
import { TbLogout } from "react-icons/tb";
import { apiUrl } from "../../config/apiUrl";

const RenderItem = ({ icon, title, subMenu = [], path }) => {
  const navigate = useNavigate();
  const active = useLocation()?.pathname;
  const [subnav, setSubnav] = useState(false);

  const subActive = subMenu.find((item, index) => item?.path == active);

  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      <div
        className={[
          classes?.listItemContainer,
          path == active && classes?.active,
          subActive && classes?.subActive,
          subnav && classes?.marginZero,
        ].join(" ")}
        onClick={() => {
          if (subMenu?.length > 0) {
            showSubnav(!subnav);
          } else {
            navigate(path);
          }
        }}
      >
        {icon}
        <span>{title}</span>
        {subMenu?.length > 0 &&
          (subnav ? (
            <RiArrowUpSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ) : (
            <RiArrowDownSFill
              size={20}
              color={"white"}
              className={classes?.dropDownIcon}
            />
          ))}
      </div>
      {subnav &&
        subMenu.map((item, index) => {
          return (
            <div
              className={[
                classes?.innerItemContainer,
                item?.path == active && classes?.active,
              ].join(" ")}
              key={index}
              onClick={() => {
                navigate(item?.path);
              }}
            >
              <span>{item.label}</span>
            </div>
          );
        })}
    </>
  );
};

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user: userData, fcmToken } = useSelector(
    (state) => state?.authReducer
  );
  // const socket = io(apiUrl);

  const HandleSubmitSignOut = () => {
    // socket.emit("logout", userData?._id, fcmToken);
    dispatch(signOutRequest());
    navigate("/login");
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes.logoContainer}>
        <img src={logoMain} />
      </div>
      <div className={classes.itemsContainer}>
        {/* <RenderItem
          icon={
            <AiFillDashboard size={22} color={"rgba(255, 255, 255, 0.3)"} />
          }
          title={"Dashboard"}
          path={"/"}
        /> */}

        <RenderItem
          icon={<VscRepo size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"Portfolio"}
          path={"/"}
        />

        <RenderItem
          icon={<FaTasks size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"Daily Works"}
          path={"/daily-works"}
        />

        <RenderItem
          icon={<RiListCheck2 size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"CMS"}
          subMenu={[
            { label: "Home Cms", path: "/home-cms" },
            { label: "Footer Cms", path: "/footer-cms" },
          ]}
        />

        {/* <RenderItem
          icon={<RiListCheck2 size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"CMS"}
          subMenu={[
            { label: "Footer Cms", path: "/footer-cms" },
            // { label: "Goals", path: "/Goals" },
          ]}
        /> */}

        <RenderItem
          icon={<MdEmail size={22} color={"rgba(255, 255, 255, 0.3)"} />}
          title={"Contact Us"}
          path={"/contact-us"}
        />

        <div
          className={[classes?.listItemContainer].join(" ")}
          onClick={() => {
            HandleSubmitSignOut();
          }}
        >
          <TbLogout size={22} color={"rgba(255, 255, 255, 0.3)"} />
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
