import React, { useRef } from "react";
import { CgImage } from "react-icons/cg";
import { MdUpload, MdModeEdit, MdClose } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { imageUrl } from "../../config/apiUrl";
import { GalleryImage } from "../../constant/imagePath";
import classes from "./UploadImageBox.module.css";

function UploadImageBox({
  state,
  setter,
  label,
  edit = true,
  onDelete,
  onEdit,
  onClose,
  isCloseable,
  imgRef=null,
  onLoad

}) {
  const inputRef = useRef(null);
  console.log("state", state);
  return (
    <div className={classes.box}>
      {label && <label>{label}</label>}

      <div className={classes.uploadImageBox}>
        {/* Close Icon */}
        {isCloseable && (
          <span className={classes.closeIcon} onClick={onClose}>
            <MdClose />
          </span>
        )}
        {state?.name || typeof state == "string" ? (
          <div className={classes.imageUploaded}>
            <img
              src={
                typeof state == "object"
                  ? URL.createObjectURL(state)
                  : imageUrl(state)
              }
              ref={imgRef}
              onLoad={onLoad}
            />
            <div className={classes.editAndDelete}>
              {edit && (
                <>
                  {onDelete && <div className={classes.icon} onClick={onDelete}>
                    <RiDeleteBinLine />
                  </div>}

                  {onEdit ?
                    <div className={classes.icon} onClick={onEdit}>
                      <MdModeEdit />
                    </div>
                    : <div
                      className={classes.icon}
                      onClick={() => inputRef.current.click()}
                    >
                      <MdModeEdit />
                    </div>
                  }
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={classes.uploadBox}>
            <img src={GalleryImage} className={classes.icon} />
            {/* <CgImage className={classes.icon} /> */}
            <div
              className={classes.uploadIcon}
              onClick={() => inputRef.current.click()}
            >
              <MdUpload />
            </div>
          </div>
        )}
      </div>
      {/* Input For Image Upload */}
      <input
        hidden
        type={"file"}
        ref={inputRef}
        onChange={(e) => setter(e.target.files[0])}
      />
    </div>
  );
}

export default UploadImageBox;
