import React from "react";
import Button from "../../Components/Button";
import { FiAlertTriangle } from "react-icons/fi";
import classes from "./LeadDetailModal.module.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { IconButton } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";

const LeadDetailModal = ({ show, setShow, data }) => {
  return (
    <>
      <style jsx>{`
        .modal-content {
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
          position: relative;
        }
        .modal-body {
          padding: 24px;
        }
        .modal-header {
          flex-direction: column;
          background: var(--dashboard-main-color);
          border-bottom: none;
          padding: 0.75rem;
        }
        .name {
          font-size: 18px;
          color: var(--text-color-black);
        }
      `}</style>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <h4 className={[classes.headingText].join(" ")}>Lead Detail</h4>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.content}>
            <div className={classes.mainDiv}>
              <p className={[classes.message].join(" ")}>
                <b>Name: </b>
                {data?.name}
              </p>
              <p className={[classes.message].join(" ")}>
                <b>Email: </b>
                {data?.email}
              </p>
              <p className={[classes.message].join(" ")}>
                <b>Message: </b>
                {data?.message}
              </p>
            </div>

            <IconButton
              onClick={() => {
                setShow(false);
              }}
              className={classes?.closeButton}
            >
              <AiOutlineCloseCircle />
            </IconButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeadDetailModal;
