import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PackageCard from "../../Components/PackageCard";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import classes from "./packages.module.css";

function Packages() {
  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container fluid className={classes.container}>
          <h2 className={classes.heading}>Packages</h2>
          <Row>
            {["", "", ""]?.map((item) => (
              <Col md={4}>
                <PackageCard />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </SideBarSkeleton>
  );
}

export default Packages;
