// export const apiUrl = "https://ee4e-124-29-228-217.au.ngrok.io";
// export const apiUrl = "https://nisseby-backend.herokuapp.com";
export const apiUrl = "https://nisseby-be.herokuapp.com";

export const imageUrl = (link) => {
  return `${apiUrl}/api/images/${link}`;
};
export const socketURL = `${apiUrl}`;
export const stripe_public_key = ``;
// export const stripe_public_key = `pk_test_51L8FqNL51LXb45sopLMv0wOiEZvg9NhshZWh0vWC7Okl7Alm2n01MGG89jdvovL41Fw98xNs1I0VqIn29KCD9JQc00acfYgJBk`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let innerKey in data[key]) {
        formData.append(key, data[key][innerKey]);
      }
    } else formData.append(key, data[key]);
  }
  return formData;
};

export const firebaseVapidObject = {
  vapidKey: "",
  // "BKlmZ2T6iSDR1mOAaQOzBEbwHdlYX71OqZOwvvja_oDliCf9eRrb8Rgo_dasRs9WCpgSppH0O0kvtIOc959bO0U",
};

export var recordsLimit = 10;

export const falsyArray = [
  null,
  undefined,
  "",
  0,
  false,
  NaN,
  "null",
  "undefined",
  "false",
  "0",
  "NaN",
];
