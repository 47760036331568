import React, { useState, useRef } from "react";
import ReactCodeInput from "react-code-input";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import classes from "./VerifyCodePage.module.css";
import { Logo } from "../../constant/imagePath";
import Button from "../../Components/Button";
import InputClasses from "../../Components/Input/input.module.css";
import { apiHeader, BaseURL } from "../../config/apiUrl";
import { Patch, Post } from "../../Axios/AxiosFunctions";

const VerifyCodePage = () => {
  // variables and states
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email || "test@gmail.com";

  const inputRef = useRef();
  const [pinCode, setPinCode] = useState("");
  const [showPinCode, setShowPinCode] = useState(true);
  const [loading, setLoading] = useState(false);
  const header = apiHeader();
  // const url = BaseURL("users/code-verify");
  const url = BaseURL("auth/match-otp");

  // functions Handle Verify Pin Code
  const handleSubmit = async () => {
    if (pinCode?.length < 6) {
      return toast.error("Please type Otp Code Correctly");
    }
    setLoading(true);
    const response = await Patch(url, { otpCode: pinCode, email }, header);
    setLoading(false);
    if (response !== undefined) {
      navigate("/reset-password", {
        state: {
          email: email,
        },
      });
    }
  };

  return (
    <>
      <Container fluid className={classes.forgotContainer}>
        <style jsx>{`
          .${InputClasses.Container} {
            width: 100%;
          }
          .${InputClasses.inputPassContainer} {
            background: white;
          }

          .${InputClasses.leftIconBox} > svg {
            width: 25px;
            height: 25px;
          }
          .${InputClasses.inputBox} {
            padding-left: 55px !important;
          }
        `}</style>

        <Row className={`g-0 ${classes.rowConatiner}`}>
          <Col md={6} className={classes.leftContainer}></Col>

          <Col md={6} className={classes.rightContainer}>
            <div className={classes.imageContainer}>
              <img src={Logo} alt="logo" />
            </div>

            <h3 className={classes.heading}>Verfication!</h3>
            <span className={classes.description}>
              One Time Password (OTP) has been send to your email{" "}
              {<span className={classes.userEmail}>{email}</span>}.
            </span>
            <div
              style={{
                alignSelf: "flex-start",
              }}
            >
              <div className={classes.otpTitleContainer}>
                <span className={classes.otpTitle}>Enter code below</span>
                <div
                  className={classes.showOtpContainer}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPinCode((p) => !p);
                  }}
                >
                  <div className="forgot__rightEye">
                    {showPinCode ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                </div>
              </div>

              <ReactCodeInput
                ref={inputRef}
                type={showPinCode ? "text" : "password"}
                fields={6}
                onChange={(code) => setPinCode(code)}
                value={pinCode}
                style={{
                  width: "100%",
                }}
              />
            </div>

            <div className={classes.buttonContainer}>
              <Button
                label={"Reset"}
                className={[classes.generalButton, classes.resetButton].join(
                  " "
                )}
                disabled={loading}
                onClick={() => {
                  setShowPinCode(true);
                  setPinCode("");
                  if (inputRef.current.textInput[0])
                    inputRef.current.textInput[0].focus();
                  inputRef.current.state.input[0] = "";
                  inputRef.current.state.input[1] = "";
                  inputRef.current.state.input[2] = "";
                  inputRef.current.state.input[3] = "";
                  inputRef.current.state.input[4] = "";
                  inputRef.current.state.input[5] = "";
                }}
              />
              <Button
                label={loading ? "Please wait" : "Change Password"}
                className={[classes.generalButton, classes.submitButton].join(
                  " "
                )}
                disabled={loading}
                onClick={handleSubmit}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VerifyCodePage;
