import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ProfileSetting.module.css";
import TextArea from "../../Components/TextArea";
import Input from "../../Components/Input";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import Button from "../../Components/Button";
import Checkbox from "../../Components/Checkbox";
import DropDown from "../../Components/DropDown";
import AddImageComponent from "../../Components/AddImageComponent";
import ProfileWithEditButton from "../../Components/ProfileWithEditButton";

function ProfileSetting() {
  const [photo, setPhoto] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  // const [pass, setLocation]=useState('')
  const [hotelName, setHotelName] = useState("");
  const [hotelClass, setHotelClass] = useState("");
  const [hotellocation, setHotelLocation] = useState("");
  const [description, setDescription] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [foodType, setFoodType] = useState([]);
  const [allowFree, setAllowFree] = useState([]);
  const [image, setImage] = useState([{}, {}]);
  const handleImagesUpdate = (data) => {
    setImage(data);
  };
  return (
    <SideBarSkeleton>
      <div className={classes.page}>
        <section className={classes.accountInfoSection}>
          <Container className={classes.container}>
            <Row className={classes.contentRow}>
              <Col md={12} className={classes.infoBox}>
                <Row>
                  <Col md={12}>
                    <div className={classes.mainHeading}>
                      <h2>Account Info</h2>
                      <Button
                        label={"Change Password"}
                        className={classes.changePass}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <ProfileWithEditButton
                      edit={true}
                      setUpdateImage={setPhoto}
                      updateImage={photo}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"First Name"}
                      placeholder={"Enter first name here"}
                      value={firstName}
                      setter={setFirstName}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Last Name"}
                      placeholder={"Enter last name here"}
                      value={lastName}
                      setter={setLastName}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Email Address"}
                      placeholder={"Enter email here"}
                      value={email}
                      setter={setEmail}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Phone Number"}
                      placeholder={"Enter phone number here"}
                      value={phone}
                      setter={setPhone}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Location"}
                      placeholder={"Enter location here"}
                      value={location}
                      setter={setLocation}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}></Col>
                </Row>

                <Row className={classes.hotelInfo_row}>
                  <h2>Hotel Info</h2>

                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Hotel Name"}
                      placeholder={"Enter phone number here"}
                      value={hotelName}
                      setter={setHotelName}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <DropDown
                      setter={setHotelClass}
                      value={hotelClass}
                      placeholder={"5 Star"}
                      customStyle={{
                        borderRadius: "10px",
                        border: "1px solid var(--input-border-color)",
                        padding: "2px 15px",
                      }}
                      label={"Hotel Class"}
                    />
                  </Col>
                  <Col lg={4} md={6} className={classes.mt24}>
                    <Input
                      label={"Location"}
                      placeholder={"Enter phone number here"}
                      value={hotellocation}
                      setter={setHotelLocation}
                      inputStyle={{ paddingLeft: "24px" }}
                    />
                  </Col>
                  {/* Check Boxes */}
                  <Row>
                    <Col md={4} sm={4} xs={6} className={classes.mt24}>
                      <Checkbox
                        label={"Breakfast"}
                        className={classes.checkbox_check}
                        setValue={setFoodType}
                        value={foodType}
                      />
                    </Col>
                    <Col md={4} sm={4} xs={6} className={classes.mt24}>
                      <Checkbox
                        label={"Lunch"}
                        className={classes.checkbox_check}
                        setValue={setFoodType}
                        value={foodType}
                      />
                    </Col>
                    <Col md={4} sm={4} xs={6} className={classes.mt24}>
                      <Checkbox
                        label={"Dinner"}
                        className={classes.checkbox_check}
                        setValue={setFoodType}
                        value={foodType}
                      />
                    </Col>
                  </Row>
                  <Col lg={12}>
                    <div className={classes.description_main}>
                      <TextArea
                        setter={setDescription}
                        value={description}
                        label={"Description"}
                      />
                    </div>
                  </Col>

                  <Row>
                    <Col md={6} sm={6} xs={12}>
                      <Checkbox
                        label={"Allow Discount"}
                        className={classes.checkbox_check}
                        setValue={setAllowFree}
                        value={allowFree}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                      <Checkbox
                        label={"Free Calcellation"}
                        className={classes.checkbox_check}
                        setValue={setAllowFree}
                        value={allowFree}
                      />
                    </Col>
                  </Row>
                  <Col lg={4} md={6}>
                    <div className={classes.discountPer}>
                      <Input
                        placeholder={"Discount percenatge"}
                        value={discountPercentage}
                        setter={setDiscountPercentage}
                        inputStyle={{ paddingLeft: "24px" }}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className={classes.photosColMain}>
                      <p>Photos</p>
                      <div className={classes.imageComp_main}>
                        {image?.map((item, index) => {
                          return (
                            <AddImageComponent
                              item={item}
                              index={index}
                              onSubmit={handleImagesUpdate}
                              key={index}
                              initialState={image}
                              boxClass={classes.imageBox}
                            />
                          );
                        })}
                        {image.length < 5 && (
                          <AddImageComponent
                            onSubmit={handleImagesUpdate}
                            initialState={image}
                            isAddEmpty={true}
                            boxClass={classes.imageBox}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Col md={12}>
                  <Button label={"Save Changes"} className={classes.saveBtn} />
                </Col>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </SideBarSkeleton>
  );
}

export default ProfileSetting;
