import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import SideBarSkeleton from "../../../src/Components/SideBarSkeleton";
import classes from "./HomePageCms.module.css";
import { Col, Row } from "react-bootstrap";
import  Button  from "../../../src/Components/Button/index";
import Input from "../../../src/Components/Input/index";
import UploadImageBox from "../../../src/Components/UploadImageBox/index";
import { apiHeader, BaseURL, CreateFormData } from "../../../src/config/apiUrl";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../../src/Axios/AxiosFunctions";

const HomePageCms = () => {
  // const { accessToken } = useSelector((state) => state?.authReducer);
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  // section 1
  const [data, setData] = useState(null);
  const [pageName, setPageName] = useState("");
  const [loading, setLoading] = useState(false);
  const [homeSection1Heading, setHomeSection1Heading] = useState("");
  const [homeSection1CoverImage, setHomeSection1CoverImage] = useState({});
  const [homeSection2MiniHeading, setHomeSection2MiniHeading] = useState("");
  const [homeSection2Heading, setHomeSection2Heading] = useState("");
  const [homeSection2Description, setHomeSection2Description] = useState("");
  const [homeSection2CoverImage, setHomeSection2CoverImage] = useState({});
  const [homeSection3MiniHeading, setHomeSection3MiniHeading] = useState("");
  const [homeSection3Heading, setHomeSection3Heading] = useState("");
  const [homeSection3Description, setHomeSection3Description] = useState("");
  const [homeSection3CoverImage, setHomeSection3CoverImage] = useState({});
  const [homeSection4MiniHeading, setHomeSection4MiniHeading] = useState("");
  const [homeSection4Heading, setHomeSection4Heading] = useState("");
  const [homeSection4Description, setHomeSection4Description] = useState("");
  const [homeSection4CoverImage, setHomeSection4CoverImage] = useState({});
  const [homeSection5MiniHeading, setHomeSection5MiniHeading] = useState("");
  const [homeSection5Heading, setHomeSection5Heading] = useState("");
  const [homeSection5Description, setHomeSection5Description] = useState("");
  const [homeSection5CoverImage, setHomeSection5CoverImage] = useState({});

  const getPageData = async () => {
    const url = BaseURL("cms/pages/all?pages=home");
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      console.log(response?.data, "///***---");
      const homeData = response?.data?.data?.pages;
      setData(homeData)
      setPageName(homeData?.pageName)
      setHomeSection1Heading(homeData?.homeSection1Heading)
      setHomeSection1CoverImage(homeData?.homeSection1CoverImage)
      setHomeSection2MiniHeading(homeData?.homeSection2MiniHeading)
      setHomeSection2Heading(homeData?.homeSection2Heading)
      setHomeSection2Description(homeData?.homeSection2Description)
      setHomeSection2CoverImage(homeData?.homeSection2CoverImage)
      setHomeSection3MiniHeading(homeData?.homeSection3MiniHeading)
      setHomeSection3Heading(homeData?.homeSection3Heading)
      setHomeSection3Description(homeData?.homeSection3Description)
      setHomeSection3CoverImage(homeData?.homeSection3CoverImage)
      setHomeSection4MiniHeading(homeData?.homeSection4MiniHeading)
      setHomeSection4Heading(homeData?.homeSection4Heading)
      setHomeSection4Description(homeData?.homeSection4Description)
      setHomeSection4CoverImage(homeData?.homeSection4CoverImage)
      setHomeSection5MiniHeading(homeData?.homeSection5MiniHeading)
      setHomeSection5Heading(homeData?.homeSection5Heading)
      setHomeSection5Description(homeData?.homeSection5Description)
      setHomeSection5CoverImage(homeData?.homeSection5CoverImage)
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const submit = async () => {
    const url = BaseURL("cms/page/update");
    const params = {
      _id: data?._id,
      pageName,
      homeSection1Heading,
        homeSection1CoverImage,
        homeSection2MiniHeading,
        homeSection2Heading,
        homeSection2Description,
        homeSection2CoverImage,
        homeSection3MiniHeading,
        homeSection3Heading,
        homeSection3Description,
        homeSection3CoverImage,
        homeSection4MiniHeading,
        homeSection4Heading,
        homeSection4Description,
        homeSection4CoverImage,
        homeSection5MiniHeading,
        homeSection5Heading,
        homeSection5Description,
        homeSection5CoverImage,
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == {}) {
        return toast.error(`Please fill all the fields!`);
      }
    }

    setLoading(true);

    const formDataParams = CreateFormData(params);
    const response = await Post(
      url,
      formDataParams,
      apiHeader(accessToken, true)
    );
    if (response !== undefined) {
      console.log({ response });
      toast.success("Home page updated successfully");
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <SideBarSkeleton>
      <div className={classes.mainContainer}>
        <h4 className={classes.heading}>Home Page</h4>
        <Row className={classes.formRow}>
          {/* section 1 */}
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection1Heading}
              setter={setHomeSection1Heading}
              placeholder="home Section1 Heading"
              label="home Section1 Heading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={homeSection1CoverImage}
              setter={setHomeSection1CoverImage}
              label="home Section1 CoverImage"
              hideDeleteIcon={false}
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection2MiniHeading}
              setter={setHomeSection2MiniHeading}
              placeholder="home Section2 MiniHeading"
              label="home Section 2 MiniHeading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection2Heading}
              setter={setHomeSection2Heading}
              placeholder="home Section2 Heading"
              label="home Section2 Heading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection2Description}
              setter={setHomeSection2Description}
              placeholder="home Section2 Description"
              label="home Section2 Description"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={homeSection2CoverImage}
              setter={setHomeSection2CoverImage}
              label="home Section2 CoverImage"
              hideDeleteIcon={false}
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection3MiniHeading}
              setter={setHomeSection3MiniHeading}
              placeholder="home Section3 MiniHeading"
              label="home Section 3 MiniHeading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection3Heading}
              setter={setHomeSection3Heading}
              placeholder="home Section3 Heading"
              label="home Section3 Heading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection3Description}
              setter={setHomeSection3Description}
              placeholder="home Section3 Description"
              label="home Section3 Description"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={homeSection3CoverImage}
              setter={setHomeSection3CoverImage}
              label="home Section3 CoverImage"
              hideDeleteIcon={false}
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection4MiniHeading}
              setter={setHomeSection4MiniHeading}
              placeholder="home Section4 MiniHeading"
              label="home Section 4 MiniHeading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection4Heading}
              setter={setHomeSection4Heading}
              placeholder="home Section4 Heading"
              label="home Section4 Heading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection4Description}
              setter={setHomeSection4Description}
              placeholder="home Section4 Description"
              label="home Section4 Description"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={homeSection4CoverImage}
              setter={setHomeSection4CoverImage}
              label="home Section4 CoverImage"
              hideDeleteIcon={false}
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection5MiniHeading}
              setter={setHomeSection5MiniHeading}
              placeholder="home Section5 MiniHeading"
              label="home Section 5 MiniHeading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection5Heading}
              setter={setHomeSection5Heading}
              placeholder="home Section5 Heading"
              label="home Section5 Heading"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={homeSection5Description}
              setter={setHomeSection5Description}
              placeholder="home Section5 Description"
              label="home Section5 Description"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={homeSection5CoverImage}
              setter={setHomeSection5CoverImage}
              label="home Section5 CoverImage"
              hideDeleteIcon={false}
            />
          </Col>

          <Col md={12}>
            <Button
              label={loading ? "Submitting..." : "Update"}
              onClick={submit}
              disabled={loading}
            />
          </Col>
        </Row>
      </div>
    </SideBarSkeleton>
  );
};

export default HomePageCms;
