import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditPortfolioModal.module.css";
import UploadImageBox from "../../../src/Components/UploadImageBox/index";
import Button from "../../Components/Button";
import { toast } from "react-toastify";
import Input from "../../Components/Input";
const AddEditPortfolioModal = ({
  show,
  setShow,
  onClick,
  isLoading,
  isRequest,
  data
}) => {
  const [image, setImage] = useState({});
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [total, setTotal] = useState({ width: 0, height: 0 })
  useEffect(() => {
    setImage(data?.imageKey)
    setWidth(data?.width)
    setHeight(data?.height)
  }, [data])

  console.log({
    image
  })
  // var img = new Image(imageRef);

  return (
    <>
      <ModalSkeleton show={show} setShow={setShow} width={"600px"}
        header={`${data ? "Edit" : "Add"} Portfolio`}
      >
        <div className={classes.contentMain}>
          <Row>

            <Col md={12} className={classes.formCol}>
              <UploadImageBox
                state={image}
                setter={(e) => {
                  setImage(e)
                  if (e) {
                    var img = new Image();
                    img.src = window.URL.createObjectURL(e);
                    img.onload = function () {
                      var width = img.width,
                        height = img.height;
                      setWidth(width)
                      setHeight(height)
                      setTotal({ width, height })
                      window.URL.revokeObjectURL(img.src);
                    }
                  }
                }}
                // label="home Section1 CoverImage"
                hideDeleteIcon={true}
              //   onDelete={() => deleteImageHandler(img)}

              />
            </Col>
            <Col md={12} className={classes.mt20}>
              <Input type={'number'} setter={(e) => {
                if (typeof image == 'object' && total?.width < e) {
                  return toast.error("Image width can not be greater than original width")
                }
                setWidth(e)
              }} value={width} placeholder={'Please enter width'} label={'Width'} />
            </Col>

            <Col md={12} className={classes.mt20}>
              <Input type={'number'} setter={(e) => {
                if (typeof image == 'object' && total?.height < e) {
                  return toast.error("Image height can not be greater than original height")
                }
                setHeight(e)
              }} value={height} placeholder={'Please enter height'} label={'Height'} />
            </Col>

            <Col md={12}>
              <div className={classes.btnMain}>
                <Button
                  onClick={() => {
                    if (image == "" || image == null) {
                      return toast.error('Please select portfolio image')
                    }
                    onClick({ image,width,height, ...(data && { _id: data?._id }) })

                  }}
                  label={
                    isLoading ?
                      "Submitting..."
                      :
                      data ?
                        "Edit Portfolio"
                        :
                        "Add Portfolio"}
                  disabled={isLoading}

                />

              </div>
            </Col>
          </Row>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default AddEditPortfolioModal;
