import React, { useEffect, useState } from "react";
import classes from "./ContactUs.module.css";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import TableSkeleton from "../../Components/TableSkeleton";
import NoData from "../../Components/NoData/NoData";
import { BaseURL, recordsLimit } from "../../config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import Button from "../../Components/Button";
import PaginationComponent from "../../Components/PaginationComponent";
import ViewContactDetail from "../../Modals/ViewContactDetail";
import ContactReplyModal from "../../Modals/ContactReplyModal";
const ContactUs = () => {
  const token = useSelector((state) => state?.authReducer?.accessToken);
  console.log(token, "tokentokentokentoken");
  const [responseData, setResponseData] = useState([]);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [limit, setLimit] = useState(recordsLimit);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const getData = async (pageNo) => {
    const apiUrl = BaseURL(`contact-us?page=${pageNo}&limit=${recordsLimit}`);
    setIsApiLoading(true);
    const response = await Get(apiUrl, token);
    if (response !== undefined) {
      setIsApiLoading(false);
      setResponseData(response?.data?.data);
      setTotalPages(response?.data?.totalCount);
    }
  };
  useEffect(() => {
    getData(page);
  }, [page]);

  const handleReply = () => {};

  return (
    <SideBarSkeleton>
      <>
        <style>{`
        .table100-body{
          height:calc(100vh - 360px);
        }
        `}</style>

        <div className={classes.mainContainer}>
          <h2>Contact Us</h2>
          <div class="table100 ver1 m-b-110">
            <div class="table100-head">
              <table>
                <thead>
                  <tr class="row100 head">
                    <th
                      class="cell100 column1"
                      style={{ width: "10%", textAlign: "left" }}
                    >
                      S.No
                    </th>
                    <th
                      class="cell100 column2"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      Name
                    </th>
                    <th
                      class="cell100 column2"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      Email
                    </th>
                    <th
                      class="cell100 column1"
                      style={{ width: "30%", textAlign: "left" }}
                    >
                      Message
                    </th>
                    <th
                      class="cell100 column4"
                      style={{ width: "20%", textAlign: "left" }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {isApiLoading ? (
              <TableSkeleton rowsCount={5} colsCount={7} />
            ) : (
              <div class="table100-body js-pscroll ps ps--active-y">
                <table>
                  <tbody>
                    {responseData?.length > 0 ? (
                      responseData?.map((item, index) => (
                        <tr class="row100 body" key={item?._id}>
                          <td
                            class="cell100 column1"
                            style={{ width: "10%", textAlign: "left" }}
                          >
                            {++index}
                          </td>
                          <td
                            class="cell100 column2"
                            style={{
                              width: "20%",
                              textAlign: "left",
                            }}
                          >
                            {item?.name}
                          </td>
                          <td
                            class="cell100 column2"
                            style={{
                              width: "20%",
                              textAlign: "left",
                            }}
                          >
                            {item?.email}
                          </td>
                          <td
                            class="cell100 column2"
                            style={{
                              width: "30%",
                              textAlign: "left",
                            }}
                          >
                            {item?.message}
                          </td>
                          <td
                            class="cell100 column2"
                            style={{
                              width: "20%",
                              textAlign: "left",
                            }}
                          >
                            <div className={classes.btnMain}>
                              <Button
                                onClick={() => {
                                  setShow(true);
                                  setDetailData(item);
                                }}
                                label={"View Detail"}
                              />
                              <Button
                                onClick={() => {
                                  setOpen(true);
                                }}
                                label={"Reply"}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoData text={"No Listing Found"} />
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className={[classes.paginationDiv]}>
            <PaginationComponent
              totalPages={Math.ceil(totalPages / limit)}
              currentPage={page}
              setCurrentPage={setPage}
            />
          </div>
        </div>
        <ViewContactDetail
          detailData={detailData}
          setShow={setShow}
          show={show}
        />
        <ContactReplyModal
          setShow={setOpen}
          show={open}
          handleReply={handleReply}
        />
      </>
    </SideBarSkeleton>
  );
};

export default ContactUs;
