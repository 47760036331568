import React from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./ContactReplyModal.module.css";
import TextArea from "../../Components/TextArea";
import Button from "../../Components/Button";
const ContactReplyModal = ({ show, setShow, handleReply }) => {
  return (
    <>
      <ModalSkeleton show={show} setShow={setShow} borderRadius={"10px"}>
        <div className={classes.content}>
          <h6>Reply</h6>
          <TextArea rows={6} placeholder={"Enter Your Reply"} />
          <span>
            <Button label={"Send"} />
          </span>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default ContactReplyModal;
