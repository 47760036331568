import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import SideBarSkeleton from "../../Components/SideBarSkeleton";
import classes from "./FooterPageCms.module.css";
import { Col, Row } from "react-bootstrap";
import Button from "../../Components/Button/index";
import Input from "../../Components/Input/index";
import UploadImageBox from "../../Components/UploadImageBox/index";
import { apiHeader, BaseURL, CreateFormData } from "../../config/apiUrl";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";

const FooterPageCms = () => {
  // const { accessToken } = useSelector((state) => state?.authReducer);
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  // section 1
  const [data, setData] = useState(null);
  const [pageName, setPageName] = useState("");
  const [loading, setLoading] = useState(false);
  const [footerImage, setFooterImage] = useState({});
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [links, setLinks] = useState([
    {
      name: "",
      link: "",
    },
    {
      name: "",
      link: "",
    },
  ]);

  const getPageData = async () => {
    const url = BaseURL("cms/pages/all?pages=footer");
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      console.log(response?.data, "///***---");
      const footerData = response?.data?.data?.pages;
      setData(footerData);
      setPageName(footerData?.pageName);
      setFooterImage(footerData?.footerImage);
      setDescription(footerData?.description);
      setAddress(footerData?.address);
      setEmail(footerData?.email);
      setPhoneNo(footerData?.phoneNo);
      setLinks(footerData?.links);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const submit = async () => {
    const url = BaseURL("cms/page/update");
    const params = {
      _id: data?._id,
      pageName,
      footerImage,
      description,
      address,
      email,
      phoneNo,
      links,
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == {}) {
        return toast.error(`Please fill all the fields!`);
      }
    }

    const formData = new FormData();
    formData.append("_id", data?._id);
    formData.append("pageName", pageName);
    formData.append("footerImage", footerImage);
    formData.append("description", description);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("phoneNo", phoneNo);
    for (let key in links) {
      formData.append(
        links?.length > 1 ? `links` : "links[]",
        JSON.stringify(links[key])
      );
    }

    setLoading(true);

    // const formDataParams = CreateFormData(params);
    const response = await Post(url, formData, apiHeader(accessToken, true));
    if (response !== undefined) {
      toast.success("Footer page updated successfully");
      setLoading(false);
    } else {
      setLoading(false);
      // clear form data
      formData.delete("_id");
      formData.delete("pageName");
      formData.delete("footerImage");
      formData.delete("description");
      formData.delete("address");
      formData.delete("email");
      formData.delete("phoneNo");
      formData.delete("links");
    }
  };

  return (
    <SideBarSkeleton>
      <div className={classes.mainContainer}>
        <h4 className={classes.heading}>Footer Page</h4>
        <Row className={classes.formRow}>
          {/* section 1 */}

          <Col md={6} className={classes.formCol}>
            <UploadImageBox
              state={footerImage}
              setter={setFooterImage}
              label="Footer Image"
              hideDeleteIcon={false}
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={description}
              setter={setDescription}
              placeholder="description"
              label="description"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={address}
              setter={setAddress}
              placeholder="address"
              label="address"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={email}
              setter={setEmail}
              placeholder="email"
              label="email"
            />
          </Col>
          <Col md={6} className={classes.formCol}>
            <Input
              value={phoneNo}
              setter={setPhoneNo}
              placeholder="phone No"
              label="phone No"
            />
          </Col>
          <h5
            onClick={() => {
              setLinks((prev) => [...prev, { name: "", link: "" }]);
            }}
            className={classes.addLink}
          >
            Add Link
          </h5>

          {links?.map((item, index) => {
            return (
              <Col md={12} className={classes.container}>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Cancel
                    onClick={() => {
                      let newArray = [...links];

                      newArray.splice(index, 1);
                      setLinks(newArray);
                    }}
                  />
                </div>
                <Row>
                  <input
                    //   value={item?.name}
                    placeholder="name"
                    value={item?.name}
                    onChange={(data) => {
                      setLinks(
                        (prev) => [...prev],
                        (item.name = data.target.value)
                      );
                    }}
                    // disabled={false}
                    // onChange={(data)=>{setLinks((prev)=>(prev),[item?.links = data])}}
                    className={classes.Input}
                  />
                </Row>
                <Row>
                  <input
                    value={item?.link}
                    placeholder="Link"
                    onChange={(data) => {
                      setLinks(
                        (prev) => [...prev],
                        (item.link = data.target.value)
                      );
                    }}
                    className={classes.Input}
                  />
                </Row>
              </Col>
            );
          })}

          <Col md={12}>
            <Button
              label={loading ? "Submitting..." : "Update"}
              onClick={submit}
              disabled={loading}
            />
          </Col>
        </Row>
      </div>
    </SideBarSkeleton>
  );
};

export default FooterPageCms;
